import React, { useContext, useState } from 'react';
import Common from './Common';
import { AppContextData, modes } from '../../App';
import Axios from 'axios';
import environment from '../../environment';

function List() {
    const context = useContext(AppContextData);
    const toggleEditMode = (e, editVariableName, editVariableValue) => {
        e.preventDefault();
        context.setState({
            ...context,
            loading: true
        })
        Axios.post(`${environment.url}/get/env/${context.env}/var/${editVariableName}`, null,
        {
            headers: {
                token: context.account.token
            }
        }).then(i => {
            const data = i.data;
            if (data.error === true) {
                console.log(i.data);
                errorHandler();
                return;
            }
            context.setState({
                ...context,
                mode: modes.edit,
                var: {
                    name: data.name,
                    value: data.value
                },
                loading: false,
                error: false,
                errorMessage: ''
            })
        }).catch(i => {
            console.log(i);    
            errorHandler();        
        })
        
    }
    const errorHandler = () => {
        context.setState({
            ...context,
            error: true,
            errorMessage: 'Something is wrong',
            loading: false
        })
    }
    const filter = (e) => {
        e.preventDefault();
        const sub = context.loadDataObservable(e, context).subscribe({
            next: (result) => {
                const data = result.filter(i => 
                    i.name.toLowerCase().includes(filterName.toLowerCase()) &&
                    i.value.toLowerCase().includes(filterValue) &&
                    i.version.toString().toLowerCase().includes(filterVersion.toLowerCase())
                    );
                context.setState({
                    ...context,
                    data
                })
            },
            error: (err) => {
                console.log(err);
                sub.unsubscribe();
            },
            complete: () => {
                sub.unsubscribe();
            }
        });
    }
    const [filterName, setFilterName] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterVersion, setFilterVersion] = useState('');
    return (<div>
        <Common />
        <div className="row bold">                
            <div className="col-6 border-2">
                Name
            </div>
            <div className="col-4 border-2">
                Value
            </div>     
            <div className="col-1 border-2">
                Version
            </div>           
            <div className="col-1">
            </div>           
        </div>
        <form className="row margin-ud-10" onSubmit={filter}>                
            <div className="col-6">
                <input placeholder="Name Filter" className="width-auto" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
            </div>
            <div className="col-4">
                <input placeholder="Value Filter" className="width-auto" value={filterValue} onChange={(e) => setFilterValue(e.target.value)}  />
            </div>     
            <div className="col-1">
                <input placeholder="Version Filter" className="width-auto" value={filterVersion} onChange={(e) => setFilterVersion(e.target.value)}  />
            </div>           
            <div className="col-1">
                <input type="submit" className="btn btn-info" value="Filter" / >
            </div>           
        </form>
        {
            context.data.map(i => {
                const now = new Date().getTime();
                const key = now + i.name;
                return (
                    <div key={key} className="row">
                        <div className="col-6 border">
                            <p>{ i.name }</p>
                        </div>
                        <div className="col-4 border" style={{ wordBreak: 'break-all'}}>
                            <p>{ i.value }</p>
                        </div>
                        <div className="col-1 border">
                            <p> { i.version } </p>
                        </div>
                        <div className="col-1">
                        <button className="btn btn-success" onClick={(e) => toggleEditMode(e, i.name, i.value)}>Edit</button>
                        </div>
    
    
                    </div>
                );
            })
        }
        
    </div>)
}

export default List;