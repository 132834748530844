import React, { useState, useContext } from 'react';
import './App.css';
import Login from './pages/Login';
import Header from './pages/Header';
import View from './pages/authenticated/Authenticated';
import Logout from './pages/Logout';
import Popup from './pages/Popup';
import Axios from 'axios';
import { from, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import environment from './environment';

const AppContext = React.createContext();

export const modes = {
  list: 'list',
  add: 'add',
  edit: 'edit'
}

export const AppContextData = AppContext;
export let initState = {
  auth: false,
  account: {},
  error: false,
  errorMessage: '',
  success: false,
  successMessage: '',
  loading: false,
  data: [],
  env: 'DUC168_PRODUCTION',
  mode: modes.list,
  var: {
    name: '',
    value: ''
  },
  mimi: '',
  popup: false,
  popupContent: {
    title: '',
    body: ''
  }
}

function AppContextProvider(props) {
  const newInitState = {
    ...initState,
    setState: (newData) => {
      setAppState(newData)
    },
    loadData: (e, context, updateContextPart) => {
      e === undefined ? console.log('no event') : e.preventDefault();
      context.setState({ ...context, loading: true, ...updateContextPart });
      return from(Axios.post(`${environment.url}/get/env/${context.env}`,
        null, { headers: { token: context.account.token } })
        ).pipe(
          switchMap(i => {
            context.setState({ ...context, data: i.data, error: false, errorMessage: '', loading: false, ...updateContextPart });
            return of(i.data);
          }),
          catchError(i => {
            console.log(i);
            context.setState({ ...context, data: [], error: true, errorMessage: "No Data", loading: false, ...updateContextPart });
            return of('ERROR');
          })
        )
    },
    loadDataObservable: (e, context) => {
      e === undefined ? console.log('no event') : e.preventDefault();
      context.setState({ ...context, loading: true });
      return from(Axios.post(`${environment.url}/get/env/${context.env}`,
        null, { headers: { token: context.account.token } })).pipe(
          switchMap(i => {
            const data = i.data;    
            context.setState({
                ...context,
                loading: false
            });
            return of(data);
          }),
          catchError(i => {
            console.log('catch error ', i);
            context.setState({
              ...context,
              loading: false
          })
            return of('Error...')
          })
        );
    },
    exportCloudRun: (e, context) => {
      e === undefined ? console.log('no event') : e.preventDefault();
      context.setState({ ...context, loading: true });
      return from(Axios.post(`${environment.url}/get/env/${context.env}/use/cloudrun`,
       null, { headers: { 
          token: context.account.token,
         } })
         ).pipe(
           switchMap(
             i => {
              context.setState({
                ...context, 
                loading: false
                });
               return of(i.data.use);
             }
           ),
           catchError(
             i => {
              context.setState({
                ...context, 
                loading: false
                });
               console.log(i);
               return of('ERROR');
             }
           )
         );
    },
    exportEnv: (e, context) => {
      e === undefined ? console.log('no event') : e.preventDefault();
      context.setState({ ...context, loading: true });
      return from(Axios.post(`${environment.url}/get/env/${context.env}/use`,
       null, { headers: { 
          token: context.account.token,
         } })
         ).pipe(
           switchMap(
             i => {
              context.setState({
                ...context, 
                loading: false
                });
               return of(i.data.use);
             }
           ),
           catchError(
             i => {
              context.setState({
                ...context, 
                loading: false
                });
               console.log(i);
               return of('ERROR');
             }
           )
         );
    },
    showPopup: (context) => {
      context.setState({
        ...context,
        popup: true
      });
    },
    closePopup: (context) => {
      context.setState({
        ...context,
        popup: false
      })
    }
  }

  const [appState, setAppState] = useState(newInitState);
  return (
    <AppContext.Provider value={appState}>
      {props.children}
    </AppContext.Provider>
  )
}

function App() {
  console.log('on app init');
  const check = localStorage.getItem('account') != null;
  if (!!check) {
    const account = JSON.parse(localStorage.getItem('account'));
    initState = {
      ...initState,
      account: account,
      auth: true
    }
    console.log('user is logged in already.');
  } else {
    console.log('no use data in cache');
  }

  return (
    <AppContextProvider>
      <div className="App">
        <Main />
      </div>
    </AppContextProvider>
  );
}

function Main() {
  const context = useContext(AppContextData);
  const authenticated = context.auth === true && context.loading === false;
  const unAuthenticated = context.auth === false && context.loading === false;
  return (
    <div className="container" style={{ marginTop: '100px' }}>
      <Header />    
      <Popup />
      <div className="error">{context.errorMessage}</div>
      <div className="success">{context.successMessage}</div>
      {!!authenticated ? <div className="margin-ud-10">Hello {context.account.firstName} {context.account.lastName} ! <Logout /></div> : ''}
      {!!unAuthenticated ? <Login /> : ''}
      {!!authenticated ?
        <div className="row">
          <div className="col-12">
            <View />
          </div>
        </div>
        : ''}
      {context.loading === true ? <div>Loading...</div> : ''}
    </div>
  )
}

export default App;
