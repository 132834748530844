import React, { useContext } from "react";
import { Modal, Button } from 'react-bootstrap';
import { AppContextData } from "../App";

function Popup(props) {
  const context = useContext(AppContextData);
  return (
    <div>
      <Modal show={context.popup} onHide={() => context.closePopup(context)}>
        <Modal.Header closeButton>
        <Modal.Title>{context.popupContent !== undefined ? context.popupContent.title : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <textarea style={{width: '100%', height: '300px'}}>{ context.popupContent !== undefined ? context.popupContent.body : ''}</textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => context.closePopup(context)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Popup;
