import React, { useState, useContext } from 'react';
import Axios from 'axios';
// import { connect } from 'react-redux ';
import { AppContextData } from '../App';
function LoginComponent() {
    const context = useContext(AppContextData)
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const submit = (e) => {
        e.preventDefault();
        context.setState('loading', true);
        Axios.post('https://account.api.duc168.com/duc-tran-api-account-graphql',
        {
            query: `
            query login($username: String!, $password: String!) {
                login(username: $username, password: $password) {
                    _id
                    token
                    username
                    firstName
                    lastName
                    email
                    role
                }
            }
            `,
            variables: {
                "username": username,
                "password": password
            }
        },
        {
            
        }).then(i => {
            const data = i.data;
            if (data.data === null && data.errors !== undefined && data.errors.length > 0) {
                const errorMessage = data.errors[0].message;
                context.setState({
                    ...context,
                    error: true,
                    auth: false,
                    errorMessage: errorMessage,
                    loading: false
                });                
            } else {
                console.log('auth is true');
                const account = data.data.login;
                localStorage.setItem('account', JSON.stringify(account));
                context.setState({
                    ...context,
                    error: false,
                    errorMessage: '',
                    auth: true,
                    account: account,
                    loading: false
                })
                console.log('account ', account);                
            }
        }).catch(i => {
            console.log(i);
            context.setState({
                ...context,
                loading: false
            });
        })
    }
    return (
        <div className="margin-ud-10">
            <form onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>                
            </form>
        </div>
    )
}

export default LoginComponent;