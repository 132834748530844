import React, { useContext } from 'react';
import Common from './Common';
import { AppContextData } from '../../App';
import Axios from 'axios';
import environment from '../../environment';
function Form() {
    const context = useContext(AppContextData);
    const onNameChange = (e) => {
        context.setState({
            ...context,
            var: {
                name: e.target.value,
                value: context.var.value
            }
        })
    }
    const onValueChange = (e) => {
        context.setState({
            ...context,
            var: {
                name: context.var.name,
                value: e.target.value
            }
        })
    }
    const submit = (e) => {
        e.preventDefault();
        context.setState({
            ...context,
            loading: true
        })
        Axios.post(`${environment.url}/add/var`, {
            "name": context.var.name,
            "value": context.var.value,
            "env": context.env
        }, {
            headers: {
                token: context.account.token
            }
        }).then(i => {
            const data = i.data;
            console.log(data);
            if (data.error === true) {
                context.setState({
                    ...context,
                    error: true,
                    errorMessage: 'Something is wrong',
                    loading: false
                })
                return;
            }
            context.setState({
                ...context,
                error: false,
                errorMessage: '',
                loading: false,
                success: true,
                successMessage: 'Success'
            });
            setTimeout(() => {
                context.setState({
                    ...context,
                    success: false,
                    successMessage: ''
                })
            }, 3000);

        }).catch(i => {
            console.log(i);
            context.setState({
                ...context,
                error: true,
                errorMessage: 'Something is wrong',
                loading: false
            })
        })
    }
    return (
        <div>
            <Common />
            <form onSubmit={submit}>
                <div className="form-group row">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                    <input type="text" className="form-control" id="name" placeholder="Name" value={context.var.name} onChange={onNameChange} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value" className="col-sm-2 col-form-label">Value</label>
                    <div className="col-sm-10">
                    <input type="text" className="form-control" id="value" placeholder="Value" value={context.var.value} onChange={onValueChange} />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
            
        </div>
        
    );
}
export default Form;