import React, { useContext } from 'react';
import './Authenticated.css';
import { AppContextData, modes } from '../../App';
import List from './List';
import Form from './Form';
function Authenticated() {
    const context = useContext(AppContextData);    
    const exportCloudRun = async (e) => {    
        const sub2 = context.exportCloudRun(undefined, context).subscribe({
            next: (result) => {
                context.setState({
                    ...context,
                    popup: true,
                    popupContent: {
                        title: 'Result',
                        body: result
                    }
                });
            },
            error: (err) => {
                console.log(err);
                sub2.unsubscribe();
            },
            complete: () => {
                sub2.unsubscribe();
            }
        })
        
    }
    const exportEnv = async (e) => {    
        const sub2 = context.exportEnv(undefined, context).subscribe({
            next: (result) => {
                context.setState({
                    ...context,
                    popup: true,
                    popupContent: {
                        title: 'Result',
                        body: result
                    }
                });
            },
            error: (err) => {
                console.log(err);
                sub2.unsubscribe();
            },
            complete: () => {
                sub2.unsubscribe();
            }
        })
        
    }
    return (
        <React.Fragment>
            <button className="btn btn-success" onClick={exportCloudRun}>Export For Cloud Run</button>
            <button className="btn btn-success" onClick={exportEnv}>Export .env format</button>
            { context.mode === modes.list ? <List /> : ''}
            { context.mode === modes.edit ? <Form /> : ''}
            { context.mode === modes.add ? <Form /> : ''}
            
        </React.Fragment>
    );
}

export default Authenticated;