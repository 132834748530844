import React, { useContext } from 'react';
import { AppContextData, modes } from '../../App';


function Common() {    
    const context = useContext(AppContextData);    
    const toggleAddMode = (e, mode) => {
        !!e ? e.preventDefault() : console.log('No event in toggle add mode');
        context.setState({
            ...context,
            mode: modes.add,
            var: {
                name: '',
                value: ''
            }
        })
    }
    const toggleListMode = (e) => {
        !!e ? e.preventDefault() : console.log('No event in toggle list mode');
        const sub = context.loadData(e, context, {
            mode: modes.list,
            var: {
                name: '',
                value: ''
            }
        }).subscribe(i => {
            sub.unsubscribe();
        });
        
    }
    const loadData = (e, context) => {
        const sub = context.loadData(e, context).subscribe(i => {
            sub.unsubscribe();
        })
    }
    
    return (
        <div>
            <div className="margin-ud-10"><b>Current Environement: <input value={context.env} onChange={(e) => context.setState({ ...context, env: e.target.value })} /></b></div>
            <div className="row">
                <div className="col-6">
                    { context.mode === modes.list ? <button className="btn btn-primary" onClick={(e) => loadData(e, context)}>Load Data</button> : '' }
                    { context.mode !== modes.list ? <button className="btn btn-warning margin-10" onClick={toggleListMode}>View List of Variables</button> : ''}
                </div>
                <div className="col-6">
                    <span style={{float: 'right'}}>{ context.mode !== modes.add ? <button className="btn btn-secondary margin-10" onClick={toggleAddMode}> + </button> : '' }</span>
                </div>
            </div>
            
            
        </div>
    )
}

export default Common;