import React, { useContext } from 'react';
import { AppContextData } from '../App';

function Logout() {
    const context = useContext(AppContextData);
    const makeRequest = (e) => {
        context.setState({
            ...context,
            auth: false,
            account: {},
            mimi: '',
            error: false,
            errorMessage: false
        });
        localStorage.removeItem('account');
    }
    return (
        <span>
            <button className="btn btn-danger" onClick={makeRequest}>Logout</button>
        </span>
    )
}

export default Logout;